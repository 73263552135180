const primary = '#1d8cf8'
const error = '#c221a9'
const text = '#ffffff'
const landingPageCard = '#2d2d39'

export default {
  primary,
  error,
  text,
  landingPageCard,
}
